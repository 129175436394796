import { useEffect, useRef } from "react";
import "../assets/css/Button.css";
import { Link } from "react-router-dom";

function Button(props) {
  const btn = useRef(null);

  let type = props.type === "secondary" ? "secondary" : "";
  let arrowed = props.arrowed === true ? "arrow" : "";
  let bold = props.bold === false ? "not_bold" : "";

  const glowing = (e) => {
    const { x } = btn.current.getBoundingClientRect();
    btn.current.style.setProperty("--x", e.clientX - x);
    //btn.current.style.setProperty('--y', e.clientY - y);
  };

  useEffect(() => {
    const button = btn.current;
    button.addEventListener("mousemove", glowing);
    return () => button.removeEventListener("mousemove", glowing);
  }, [btn]);

  if (props.to !== undefined)
    return (
      <Link
        {...props}
        to={props.to}
        ref={btn}
        className={
          "button " + type + " " + arrowed + " " + bold + " " + props.className
        }
      >
        {props.children}
      </Link>
    );
  else
    return (
      <button
        {...props}
        ref={btn}
        className={
          "button " + type + " " + arrowed + " " + bold + " " + props.className
        }
      >
        {props.children}
      </button>
    );
}

export default Button;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Page(props) {
  const location = useLocation();

  useEffect(() => {
    // Check if the page has already loaded
    if (document.readyState === "complete") location.state = "loaded";
    else location.state = "loading";
  }, [location]);

  return <div {...props}>{props.children}</div>;
}

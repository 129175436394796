import Lottie from "react-lottie-player";
import  loadingAnimation from '../assets/json/loading.json';
import { Suspense } from "react";


function Loading() {
    return(
    <Lottie
        loop
        play
        animationData={loadingAnimation}
        style={{width: 50}}
    />)
}

export default function Lazy(props) {
    return(<Suspense fallback={<Loading />}>
        <div {...props}>
            {props.children}
        </div>
    </Suspense>)
}
import React, { useCallback } from 'react';

import Button from "../components/Button";

import Particles from "react-particles";
import { loadFull } from "tsparticles";
import particlesOptions from "../assets/json/relaxing.json";
import Page from "../components/Page";
import Lottie from 'react-lottie-player';
import ours from '../assets/json/ours.json';

import "../assets/css/ErrorPage.css";


export default function ErrorPage() {

  const particlesInit = useCallback(main => {
      loadFull(main);
  }, []);

  return (
    <Page id="error-page">

      <Particles options={particlesOptions} init={particlesInit} style={{height: "100vh", filter: "blur(100px)",   mixBlendMode: "lighten"}}/>

      <div className="row align-items-center justify-content-center">
        <span className='col text-end'>4</span>
          <Lottie
            animationData={ours}
            play={true}
            loop={true}
            style={{width: "30%"}}
            className='col'
          />
          <span className='col text-start'>4</span>
      </div>
      <div className='row justify-content-center'>
        <p className='text-center oops'>Oops!</p>
        <p className='text-center'>It seems you are lost<br />Here's a bear to keep you safe</p>
        <Button to="/" type="secondary">Go back to safety</Button>
      </div>
    </Page>
  );
}
import React, { useCallback, useState, useRef, useEffect } from "react";

import Lottie from "react-lottie-player";

import Particles from "react-particles";
import { loadFull } from "tsparticles";
import particlesOptions from "../assets/json/particles.json";

import Card from "../components/Card";
import ViewDiv from "../components/ViewDiv";

import logoAnimation from "../assets/json/logo.json";
import homeAnimation from "../assets/json/homeAnimation.json";
import learnAnimation from "../assets/json/learn.json";
import trainAnimation from "../assets/json/train.json";

import lightIcon from "../assets/img/light.svg";
import mountainIcon from "../assets/img/mountain.svg";

import "../assets/css/Home.css";
import Page from "../components/Page";

export default function Home() {
  const [fadeIn, setFadeIn] = useState("hidden");
  const [ready, setReady] = useState(false);
  const [logoStyle, setLogoStyle] = useState({
    width: 200,
    height: 200,
    filter: "brightness(0) invert(1)",
  });
  const movingLogo = useRef();
  const staticLogo = useRef();

  // Load particles background
  const particlesInit = useCallback((main) => {
    loadFull(main);
  }, []);

  // Timeout before triggering logo animation
  useEffect(() => {
    setTimeout(() => setReady(true), 1000);
  }, []);

  // Logo displacement at end of animation
  const next = () => {
    let width = staticLogo.current.offsetWidth;
    let height = staticLogo.current.offsetHeight;

    let transformX =
      staticLogo.current.offsetLeft - movingLogo.current.wrapper.offsetLeft;
    let transformY =
      staticLogo.current.offsetTop - movingLogo.current.wrapper.offsetTop;

    transformY = transformY + 7;
    setFadeIn("fade_in");

    setLogoStyle({
      width: width,
      height: height,
      transform: `translate(${transformX}px, ${transformY}px) scale(1.1)`,
      filter: "brightness(0) invert(1)",
    });
  };

  return (
    <Page className="App">
      <Particles options={particlesOptions} init={particlesInit} />
      <div id="header" className="row align-items-center">
        <div id="header-content" className="w-100 row align-items-center">
          <div className="col d-flex flex-column align-items-baseline">
            <Lottie
              ref={movingLogo}
              loop={false}
              animationData={logoAnimation}
              play={ready}
              onComplete={next}
              style={logoStyle}
              id="wrapper-logo"
            />
            <div className="row ">
              <h1
                className={fadeIn}
                style={{ animationDelay: ".1s", width: "auto" }}
              >
                F
              </h1>
              <h1
                className="hidden"
                style={{ animationDelay: ".2s", width: "auto" }}
                ref={staticLogo}
              >
                Ø
              </h1>
              <h1
                className={fadeIn}
                style={{ animationDelay: ".3s", width: "auto" }}
              >
                r
              </h1>
              <h1
                className={fadeIn}
                style={{ animationDelay: ".4s", width: "auto" }}
              >
                s
              </h1>
              <h1
                className={fadeIn}
                style={{ animationDelay: ".5s", width: "auto" }}
              >
                i
              </h1>
              <h1
                className={fadeIn}
                style={{ animationDelay: ".6s", width: "auto" }}
              >
                g
              </h1>
              <h1
                className={fadeIn}
                style={{ animationDelay: ".7s", width: "auto" }}
              >
                h
              </h1>
              <h1
                className={fadeIn}
                style={{ animationDelay: ".8s", width: "auto" }}
              >
                t
              </h1>
            </div>
            <span className={"lead " + fadeIn}>
              The 1st digital forensic training platform based on{" "}
              <span style={{ fontWeight: "bold" }}>real-life cases</span>
              <br />
              <br />
              <span style={{ fontSize: "0.9em" }}>
                Coming september 2024...
              </span>
            </span>
          </div>
          <div className={"d-none d-md-block col-6 " + fadeIn}>
            {<Lottie animationData={homeAnimation} play loop mode="bounce" />}
          </div>
        </div>
      </div>

      <div className="mt-5 d-flex flex-column justify-content-center">
        <div className="row justify-content-between">
          <div className="row justify-content-center col col-md-6">
            <Card style={{ width: "600px" }}>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <Lottie
                  animationData={learnAnimation}
                  play={true}
                  loop={true}
                  style={{ width: "70%", marginBottom: "25px" }}
                />
                <span
                  className="lead"
                  style={{ fontSize: "2rem", fontWeight: "bold" }}
                >
                  Courses
                </span>
                <p className="text-center darker p-2">
                  Learn forensic techniques and methods from basic to expert
                  with our in-depth crystal clear courses
                </p>
              </div>
            </Card>
          </div>
          <div className="row justify-content-center col col-md-6">
            <Card style={{ width: "600px" }}>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <Lottie
                  animationData={trainAnimation}
                  play={true}
                  loop={true}
                  style={{ width: "70%", marginBottom: "25px" }}
                />
                <span
                  className="lead"
                  style={{ fontSize: "2rem", fontWeight: "bold" }}
                >
                  Challenges
                </span>
                <p className="text-center darker p-2">
                  Test and improve your skills through hands-on challenges
                  covering every part of a digital forensic analysis
                </p>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <div id="body">
        <div
          style={{ padding: "250px 30px 30px 30px" }}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <ViewDiv className="body-text row">
            <div className="text-icon col-1 d-flex justify-content-center align-items-start">
              <div style={{ position: "absolute" }}>
                <img alt="icon" src={lightIcon} width="35" />
                <div
                  style={{
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    backgroundColor: "#82aee3",
                    filter: "blur(18px)",
                  }}
                ></div>
              </div>
            </div>
            <div className="col d-flex flex-column mb-5">
              <span
                style={{ fontSize: "1.7em" }}
                className="text-title lead align-self-start mb-3"
              >
                Learn
              </span>
              <span
                style={{ fontSize: "2em" }}
                className="text-detail lead darker"
              >
                <b className="white">
                  Grasp every aspect of a digital forensic investigation.
                </b>{" "}
                Our courses covers everything you need to lead a professional
                investigation.
              </span>
            </div>
          </ViewDiv>
        </div>
        <div
          style={{ padding: "30px 30px 250px 30px" }}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <ViewDiv className="body-text row">
            <div className="text-icon col-1 d-flex justify-content-center align-items-start">
              <div style={{ position: "absolute" }}>
                <img alt="icon" src={mountainIcon} width="35" />
                <div
                  style={{
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    backgroundColor: "#f0725f",
                    filter: "blur(18px)",
                  }}
                ></div>
              </div>
            </div>
            <div className="col d-flex flex-column mb-5">
              <span
                style={{ fontSize: "1.7em" }}
                className="text-title lead align-self-start mb-3"
              >
                Train
              </span>
              <span
                style={{ fontSize: "2em" }}
                className="text-detail lead darker"
              >
                <b className="white">Harder, better, faster, stronger.</b>
                <br />
                Upskill in various fields of forensic with plenty of challenges
                from beginner to expert level.
              </span>
            </div>
          </ViewDiv>
        </div>
        <div className="background"></div>
      </div>
    </Page>
  );
}

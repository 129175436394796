import Lazy from "./Lazy";

// DEMO
//const Button = lazy(() => delayForDemo(import('./Button')));

export default function Tile(props) {
  return (
    <Lazy {...props} className={"tile " + props.className}>
      {props.children}
    </Lazy>
  );
}

import React from 'react';
import Tilt from 'react-parallax-tilt';
import "../assets/css/Card.css";
import ViewDiv from './ViewDiv';

export default function Card(props) {

return (
<ViewDiv {...props} className='card_container'>
    <Tilt
        perspective={500}
        glareEnable={true}
        glareMaxOpacity={0.02}
        glarePosition={"all"}
        tiltMaxAngleX={3}
        tiltMaxAngleY={3}
        className='card'
    >
        <div className='inner_card'>
            {props.children}
        </div>
        
    </Tilt>
</ViewDiv>
);
}
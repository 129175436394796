import React, { useState, useEffect } from "react";
import "../assets/css/Navbar.css";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [solid, setSolid] = useState("");

  useEffect(() => {
    const handleScroll = () => setSolid(window.scrollY === 0 ? "" : "solid");

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className={"navbar " + solid}>
      <div>
        <Link to="/">
          <img
            alt="Logo"
            src={logo}
            style={{ width: 30, filter: "brightness(0) invert(1)" }}
          />
        </Link>
      </div>
      <div>
        <Link to="posters">Posters</Link>
      </div>
      <div></div>
    </nav>
  );
}

import Page from "../components/Page";
import Tile from "../components/Tile";

import mftPreview from "../assets/img/posterMftPreview.jpg";
import mftPdf from "../assets/pdf/mft.pdf";
import "../assets/css/Posters.css";

export default function Posters() {
  const posters = [
    {
      name: "Master the MFT",
      pdf: mftPdf,
      preview: mftPreview,
    },
  ];

  return (
    <Page className="postersContainer">
      <br />
      <h5>Posters</h5>
      <hr />
      <div>
        {posters.map((e, i) => (
          <Tile key={i} className="posterCard">
            <a
              className="posterCardOverlay"
              target="_blank"
              rel="noreferrer"
              href={e.pdf}
            >
              {" "}
            </a>
            <div className="posterCardContent">
              <span>{e.name}</span>
            </div>
            <img src={e.preview} alt={e.name} />
          </Tile>
        ))}
      </div>
    </Page>
  );
}

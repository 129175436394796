import {useRef} from 'react';
import {useIsInViewport} from '../utils';

export default function ViewDiv(props) {
    const ref1 = useRef(null);
    const isInViewport = useIsInViewport(ref1);

    const classes = props.className ? props.className : ''

    return(
        <div
            ref={ref1}
            {...props}
            className={(isInViewport ? 'show' : 'hide') + ' ' + classes}
        >
            {props.children}
        </div>
    );
}